/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetArticleBordereauListQuery,
  useGetDiverseBordereauListQuery,
  useGetMeasureUnitListQuery,
  useGetFranchiseQuery,
  useGetLineTypeListQuery,
  useGetRSEQuery,
  useGetRemiseQuery,
  useGetTVARateListQuery,
  useLazyGetInvoiceDraftQuery,
  usePostCalculInvoiceEndMutation,
  usePostImportInvoiceMutation,
  usePostSaveInvoiceDraftMutation,
  usePostSaveInvoiceMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { formatApiErrorMessage } from 'helpers/formatApiErrorMessage'
import { parseNumber } from 'helpers/numberUtils'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import RouteTitle from 'router/RouteTitle'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
// import RateField from 'components/FieldWithInputAdornment/RateField'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'
import BackButton from 'components/BackButton/BackButton'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import InvoiceLines from './InvoiceComponents/InvoiceLines'
import InvoiceTotal from './InvoiceComponents/InvoiceTotal'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { InvoiceLineRequest } from 'types/QuoteInvoice'
import {
  EtatFacture,
  type FactureAPI,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface InvoiceRequest extends FactureAPI {
  lignesFacture: InvoiceLineRequest[] | null;
}

const invoiceSchema = Yup.object().shape<Shape<InvoiceRequest>>({
  ordre: Yup.string().required('Le numéro de facture est obligatoire'),
  commentaire: Yup.string(),
  motifRefusFacture: Yup.string(),
  deductionFranchise: Yup.boolean().required(),
  rse: Yup.boolean().required(),
  remise: Yup.boolean().required(),
  sequence: Yup.string(),
  lignesFacture: Yup.array(Yup.object().shape<Shape<InvoiceLineRequest>>({
    disabled: Yup.boolean(),
    type: Yup.string().required('Le type est obligatoire'),
    unite: Yup.string(),
    codeArticle: Yup.string(),
    libelle: Yup.string(),
    quantite: Yup.number().when('type', {
      is: 'A',
      then: (schema) => schema.min(1, 'La quantité doit être supérieure à 0'),
    }),
    tva: Yup.string().nullable().when('type', {
      is: 'A',
      then: (schema) => schema.required('La tva est obligatoire'),
    }),
  })),
}).required()

export type InvoiceForm = FormikContextType<InvoiceRequest>

/* Styled components -------------------------------------------------------- */
const TitleButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`

const FirstLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  gap: 10px;
`

// const SecondLine = styled.div`
//   display: grid;
//   grid-template-columns: 2fr 1fr 2fr 1fr;
//   gap: 10px;
// `

const AddButtonContainer = styled(Button)`
  min-width: 200px;
  float: right;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: end;
  margin-top: 10px;
  margin-bottom: 25px;
`

const Declined = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface InvoicePageProps {
  edit?: boolean;
}

const InvoicePage: React.FC<InvoicePageProps> = ({ edit = false }) => {
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ counter, setCounter ] = useState<number>(1)

  const defaultLine: InvoiceLineRequest = {
    disabled: false,
    libelle: '',
    codeArticle: '',
    prixHT: 0,
    prixTTC: 0,
    prixUnitaire: 0,
    quantite: 0,
    tva: null,
    type: 'A',
    unite: '1',
  }

  const {
    currentData: lineTypeList = [],
    isFetching: isFetchingLineTypeList,
  } = useGetLineTypeListQuery()
  const {
    currentData: measureUnitList = [],
    isFetching: isFetchingMeasureUnitList,
  } = useGetMeasureUnitListQuery()
  const {
    currentData: tvaRateList = [],
    isFetching: isFetchingTVARateList,
  } = useGetTVARateListQuery()
  const {
    currentData: franchise = 0,
    isFetching: isFetchingFranchise,
  } = useGetFranchiseQuery(caseId)
  const {
    currentData: rse = 0,
    isFetching: isFetchingRse,
  } = useGetRSEQuery(caseId)
  const {
    currentData: remise = 0,
    isFetching: isFetchingRemise,
  } = useGetRemiseQuery(caseId)
  const {
    currentData: articleBordereauList = [],
    isFetching: isFetchingArticleBordereauList,
  } = useGetArticleBordereauListQuery()
  const {
    currentData: diverseBordereauList = [],
    isFetching: isFetchingDiverseBordereauList,
  } = useGetDiverseBordereauListQuery()
  const [
    getDraft,
  ] = useLazyGetInvoiceDraftQuery()
  const [
    submitImportInvoice,
    { isLoading: isFetchingImportInvoice },
  ] = usePostImportInvoiceMutation()
  const [
    submitSaveDraft,
    { isLoading: isSavingDraft },
  ] = usePostSaveInvoiceDraftMutation()
  const [
    submitSaveInvoice,
    { isLoading: isSavingInvoice },
  ] = usePostSaveInvoiceMutation()
  const [
    submitInvoiceCalculs,
    { data: calculs },
  ] = usePostCalculInvoiceEndMutation()

  const formikForm: InvoiceForm = useForm<InvoiceRequest>(
    {
      initialValues: {
        motifRefusFacture: '',
        piedDeLaFacture: undefined,
        etatDeLaFacture: {
          code: EtatFacture.Brouillon,
          libelle: 'Brouillon',
        },
        sequence: 0,
        commentaire: '',
        deductionFranchise: true,
        montantFranchise: 0,
        tauxRemise: 0,
        remise: false,
        rse: false,
        tauxRSE: 0,
        lignesFacture: [ { ...defaultLine, type: 'TI' }, defaultLine, { ...defaultLine, type: 'TTI' } ],
        ordre: '',
      },
      validationSchema: invoiceSchema,
    },
  )

  useEffect(() => {
    if (!edit) return
    const encryptedString = location.search.split('?reprendre=')[1]

    getDraft({ dossier: caseId, refuse: isValidString(encryptedString) ? true : false }).then((data) => {
      if (data.data) {
        formikForm.setValues({
          ...formikForm.values,
          ...data.data,
          lignesFacture: data.data.lignesFacture?.map((value) => value.codeArticle ? ({ ...value, disabled: true }) : value) || [],
        })
        setCounter(counter + 1)
      }
    }).catch(console.error)
  }, [ edit ])

  useEffect(() => {
    if (!isFetchingFranchise && !isFetchingRse && !isFetchingRemise) {
      formikForm.setFieldValue('montantFranchise', franchise)
      formikForm.setFieldValue('tauxRSE', rse)
      formikForm.setFieldValue('tauxRemise', remise)
    }
  }, [ isFetchingFranchise, isFetchingRse, isFetchingRemise ])

  useEffect(() => {
    const lignesFacture = structuredClone(formikForm.values.lignesFacture) || []

    lignesFacture.forEach((line, index) => {
      const result: InvoiceLineRequest = { ...structuredClone(line), prixHT: 0, prixTTC: 0 }
      let i = index
      if (!result.prixHT) {
        result.prixHT = 0
      }
      if (!result.prixTTC) {
        result.prixTTC = 0
      }

      if (line.type === 'TST') {
        for (i; lignesFacture[i].type !== 'ST' && i > 0; i--) {
          const newLine = lignesFacture[i]

          if (newLine.type === 'A') {
            result.prixHT += parseNumber(newLine.prixHT)
            result.prixTTC += parseNumber(newLine.prixTTC)
          }
        }
        if (lignesFacture[i].type === 'ST') {
          result.libelle = `Sous total - ${lignesFacture[i].libelle}`
          lignesFacture[index] = result
        }
      }
      if (line.type === 'TTI') {
        for (i; lignesFacture[i].type !== 'TI' && i > 0; i--) {
          const newLine = lignesFacture[i]

          if (newLine.type === 'A') {
            result.prixHT += parseNumber(newLine.prixHT)
            result.prixTTC += parseNumber(newLine.prixTTC)
          }
        }
        if (lignesFacture[i].type === 'TI') {
          result.libelle = `Total - ${lignesFacture[i].libelle}`
          lignesFacture[index] = result
        }
      }
      if (line.type === 'A' && !line.tva) {
        const tva = lignesFacture.find((l) => l.type === 'A' && l.tva)?.tva ?? null
        lignesFacture[index].tva = tva
      }
    })

    submitInvoiceCalculs({ caseId, data: { ...formikForm.values, lignesFacture: lignesFacture?.filter((line) => line.type === 'A') }})

    formikForm.setFieldValue('lignesFacture', lignesFacture)
  }, [ counter ])

  const updateCounter = () => {
    setCounter(counter + 1)
  }

  const addNewLineOrSection = (section: boolean = false) => {
    const lines = structuredClone(formikForm.values.lignesFacture || [])
    const tva = lines.find((line) => line.type === 'A' && line.tva)?.tva ?? null
    let newLines = lines

    if (section) {
      newLines = [
        ...lines,
        { ...defaultLine, type: 'TI' },
        { ...defaultLine, tva },
        { ...defaultLine, type: 'TTI' },
      ]
    } else {
      newLines = [
        ...lines,
        { ...defaultLine, tva },
      ]
    }

    formikForm.setFieldValue('lignesFacture', newLines)
    updateCounter()
  }

  const onSaveClick = () => {
    submitSaveDraft({ caseId, data: formikForm.values }).then((response) => {
      if (isApiError(response)) {
        toast.error(`Une erreur est survenue lors de l'enregistrement du brouillon : ${formatApiErrorMessage(response.error)}.`)
      } else {
        toast.success('Le brouillon de la facture a bien été enregistré.')
        navigate(`/dossiers/${caseId}/facture`)
      }
    }).catch(console.error)
  }

  const onSendClick = () => {
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) {
          return submitSaveInvoice({ caseId, data: formikForm.values })
        } else {
          console.log('Invoice errors', errors)
        }
      })
      .then((response) => {
        if (isApiError(response)) {
          toast.error(`Une erreur est survenue lors de l'envoi : ${formatApiErrorMessage(response.error)}.`)
        } else if (response) {
          toast.success('La facture a bien été envoyée.')
          navigate(`/dossiers/${caseId}`)
        }
      })
      .catch(console.error)
      .finally(() => formikForm.setSubmitting(false))
  }

  const onUpdateSegmentedButtons = (fieldName: 'deductionFranchise' | 'rse' | 'remise', value: boolean) => {
    formikForm.setFieldValue(fieldName, value)
    updateCounter()
  }

  const handleImportPDF = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      submitImportInvoice({
        caseId,
        data: { Fichier: Object.values(e.target.files || {})[0], Nom: e.target.files[0].name },
      }).catch(console.error)
    }
  }

  // const handleImportInfos = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   if (e.target.files?.length !== undefined && e.target.files.length > 0) {
  //     submitImportInvoice({
  //       caseId,
  //       data: { Fichier: Object.values(e.target.files || {})[0], Nom: e.target.files[0].name },
  //     }).then((data) => {
  //       if ('data' in data) {
  //         formikForm.setValues({ ...formikForm.values, ...data.data })
  //       }
  //     }).catch(console.error)
  //   }
  // }

  const booleanOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]

  const isLoading = useMemo(() => isFetchingLineTypeList || isFetchingMeasureUnitList || isFetchingTVARateList || isFetchingFranchise || isFetchingRemise || isFetchingRse || isFetchingImportInvoice || isFetchingArticleBordereauList || isFetchingDiverseBordereauList, [
    isFetchingLineTypeList,
    isFetchingMeasureUnitList,
    isFetchingTVARateList,
    isFetchingFranchise,
    isFetchingRemise,
    isFetchingRse,
    isFetchingImportInvoice,
    isFetchingArticleBordereauList,
    isFetchingDiverseBordereauList,
  ])

  return (
    <Form form={formikForm}>
      <RouteTitle title={`Facture ${caseId}`} />
      <BackButton onClick={() => navigate(`/dossiers/${caseId}`)}>
        Retourner au suvi
      </BackButton>
      <LargeTitle>
        <Declined>
          {edit ? 'Facture' : 'Nouvelle Facture'}
          {
            formikForm.values.motifRefusFacture ?
              <ColoredSquareChip color="red">
                {formikForm.values.motifRefusFacture}
              </ColoredSquareChip> :
              <div />
          }
        </Declined>
        <TitleButtonContainer>
          <AttachmentButton
            onChange={handleImportPDF}
            multiple={false}
            name="invoice-pdf-attachment"
          >
            <Button
              variant="outlined"
              component="span"
              disabled={isFetchingImportInvoice}
            >
              Lier une facture
            </Button>
          </AttachmentButton>
          <Button
            variant="outlined"
            onClick={onSaveClick}
            disabled={isSavingDraft}
          >
            Enregistrer
          </Button>
          <Button
            variant="contained"
            onClick={onSendClick}
            disabled={isSavingInvoice}
          >
            Envoyer
          </Button>
        </TitleButtonContainer>
      </LargeTitle>
      {
        isLoading ?
          <CircularProgress /> :
          <React.Fragment>
            <Card>
              <CardContent>
                <FirstLine>
                  <div>
                    <FormBoldTitle>
                      Votre numéro de facture interne
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      name="ordre"
                      placeholder="Numéro facture interne"
                      size="small"
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Déduction de la franchise
                    </FormBoldTitle>
                    <SegmentedButtons
                      options={booleanOptions}
                      setSelectedOption={(option) => onUpdateSegmentedButtons('deductionFranchise', option)}
                      selectedOption={formikForm.values.deductionFranchise}
                      smaller
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Montant franchise
                    </FormBoldTitle>
                    <PriceField
                      name="montantFranchise"
                      size="small"
                      disabled={!formikForm.values.deductionFranchise}
                    />
                  </div>
                </FirstLine>
                {/* <SecondLine>
                  <div>
                    <FormBoldTitle>
                      RSE
                    </FormBoldTitle>
                    <SegmentedButtons
                      options={booleanOptions}
                      setSelectedOption={(option) => onUpdateSegmentedButtons('rse', option)}
                      selectedOption={formikForm.values.rse}
                      smaller
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Taux RSE
                    </FormBoldTitle>
                    <RateField
                      name="tauxRSE"
                      size="small"
                      disabled={!formikForm.values.rse}
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Remise
                    </FormBoldTitle>
                    <SegmentedButtons
                      options={booleanOptions}
                      setSelectedOption={(option) => onUpdateSegmentedButtons('remise', option)}
                      selectedOption={formikForm.values.remise}
                      smaller
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Taux remise
                    </FormBoldTitle>
                    <RateField
                      name="tauxRemise"
                      size="small"
                      disabled={!formikForm.values.remise}
                    />
                  </div>
                </SecondLine> */}
                <div>
                  <FormBoldTitle>
                    Commentaire
                  </FormBoldTitle>
                  <Field
                    component={TextField}
                    name="commentaire"
                    placeholder="Commentaire"
                    size="small"
                  />
                </div>
              </CardContent>
            </Card>
            <br />
            <InvoiceLines
              formikForm={formikForm}
              measureUnitList={measureUnitList}
              lineTypeList={lineTypeList}
              tvaRateList={tvaRateList}
              updateCounter={updateCounter}
              articleBorderauList={articleBordereauList}
              diverseBorderauList={diverseBordereauList}
            />
            <ButtonContainer>
              <AddButtonContainer
                variant="contained"
                onClick={() => addNewLineOrSection(true)}
              >
                Ajouter une section
              </AddButtonContainer>
              <AddButtonContainer
                variant="contained"
                onClick={() => addNewLineOrSection(false)}
              >
                Ajouter une ligne
              </AddButtonContainer>
            </ButtonContainer>
            <InvoiceTotal calculs={calculs} />
          </React.Fragment>
      }
      <Footer />
    </Form>
  )
}

export default InvoicePage
