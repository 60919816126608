/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Button as MuiButton,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@mui/material'

/* Styled components -------------------------------------------------------- */
const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 250px;
  gap: 10px;
  font-size: .9rem;
  padding: 10px;
  min-height: 60px;
  align-items: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: flex;
    flex-direction: column;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${(props) => props.theme.media.mobile.landscape} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Title = styled.div`
  padding-left: 10px;
`

const DuoButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`

const Button = styled(MuiButton)`
  width: 100%;
  max-width: 250px;
  min-height: 37px;
  height: 100%;
  margin-left: auto;

  @media ${(props) => props.theme.media.mobile.main} {
    max-width: 100%;
  }
`

const DialogTitle = styled(MuiDialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.1rem;
  }
`

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px 0px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    padding: 0px;
  }
`

const DialogAction = styled(MuiDialogActions)`
  justify-content: center;
  margin: 20px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    margin: 10px;

    button {
      width: 100%;
      margin-left: 0px !important;
    }
  }
`

const Line = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`

const PicturesContainer = styled.div`
  display: flex;
  gap: 10px;
  width: max(400px, 35vw);
  justify-content: end;

  @media ${(props) => props.theme.media.mobile.portrait} {
    width: 100%
  }

  @media ${(props) => props.theme.media.mobile.landscape} {
    width: min(510px, 35vw);
  }

  @media ${(props) => props.theme.media.tablet} {
    width: min(510px, 35vw);
  }
`

const PicturesSubContainer = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: auto;
`

export default {
  Button,
  CardContent,
  DialogAction,
  DialogContent,
  DialogTitle,
  DuoButtonsContainer,
  Title,
  Line,
  PicturesContainer,
  PicturesSubContainer,
}
