/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { getCleanFilename } from 'helpers/mediaUtils'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowDownloadButton from '../CaseWorflowButtons/CaseWorkflowDownloadButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type {
  EtatFacture,
  JalonSimplifie,
  EtatDevis,
} from 'API/__generated__/Api'
import {
  WorkflowEtat,
  WorkflowJalon,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowJalonCardProps {
  jalon: JalonSimplifie;
  quoteState?: EtatDevis;
  invoiceState?: EtatFacture;
  comment?: string;
}

const CaseWorkflowJalonCard: React.FC<CaseWorkflowJalonCardProps> = ({
  jalon,
  quoteState,
  invoiceState,
  comment,
}) => {
  const navigate = useNavigate()
  const isReadOnly = useIsReadOnly()

  const getButton = () => {
    switch (jalon.type.code) {
      case WorkflowJalon.Devis:
        if (jalon.etat === WorkflowEtat.EnAttente) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('nouveau-devis')}
              disabled={isReadOnly}
            >
              Nouveau devis
            </CaseWorkflowStyledComponents.Button>
          )
        }
        if (jalon.etat === WorkflowEtat.Brouillon) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('devis')}
              disabled={isReadOnly}
            >
              Continuer le devis
            </CaseWorkflowStyledComponents.Button>
          )
        }
        // if (quoteState === EtatDevis.Transmis || quoteState === EtatDevis.Retransmis) {
        //   return (
        //     <CaseWorkflowStyledComponents.Button
        //       variant="contained"
        //       onClick={() => navigate('devis')}
        //       disabled={isReadOnly}
        //     >
        //       Modifier le devis
        //     </CaseWorkflowStyledComponents.Button>
        //   )
        // }
        if (jalon.etat === WorkflowEtat.Annule) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('devis?reprendre=true')}
              disabled={isReadOnly}
            >
              Modifier le devis
            </CaseWorkflowStyledComponents.Button>
          )
        }
        break
      case WorkflowJalon.Facture:
        if (jalon.etat === WorkflowEtat.EnAttente) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('nouvelle-facture')}
              disabled={isReadOnly}
            >
              Nouvelle facture
            </CaseWorkflowStyledComponents.Button>
          )
        }
        if (jalon.etat === WorkflowEtat.Brouillon) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('facture')}
              disabled={isReadOnly}
            >
              Continuer la facture
            </CaseWorkflowStyledComponents.Button>
          )
        }
        if (jalon.etat === WorkflowEtat.Annule) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('facture?reprendre=true')}
              disabled={isReadOnly}
            >
              Modifier la facture
            </CaseWorkflowStyledComponents.Button>
          )
        }
        break
      case WorkflowJalon.CompteRenduVisite:
        if (jalon.etat === WorkflowEtat.EnAttente) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('compte-rendu-sinapps')}
              disabled={isReadOnly}
            >
              Déposer le compte-rendu
            </CaseWorkflowStyledComponents.Button>
          )
        }
        break
      case WorkflowJalon.CompteRenduRDF:
        if (jalon.etat === WorkflowEtat.EnAttente) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('compte-rendu-sinapps-rdf')}
              disabled={isReadOnly}
            >
              Déposer le rapport RDF
            </CaseWorkflowStyledComponents.Button>
          )
        }
        break
      default:
        break
    }
    if (jalon.pieceJointe) {
      return (
        <CaseWorkflowDownloadButton
          name={getCleanFilename(jalon.pieceJointe?.libelle || '', jalon.pieceJointe?.fileName || '')}
          url={jalon.pieceJointe?.url || ''}
          id={jalon.pieceJointe.id || ''}
        />
      )
    }
  }

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          {jalon.libelle ?? jalon.type.libelle}
        </CaseWorkflowStyledComponents.Title>
        {getButton() ?? <div />}
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.date}
          state={jalon.etat}
          quoteState={quoteState}
          invoiceState={invoiceState}
          comment={comment}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowJalonCard
